import React, { Component, useEffect, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";
import '../css/conversation.css'
import jordan from '../images/jordan.jpg'
import restituer from '../images/restituer.png'

import http from "../http-common";

const getType = fileType => {
    return fileType.split("/")[0]
};

const createFormData = (image, info_image) => { 
    const data = new FormData(); // Nom du fichier qui sera enregistré en BDD
    /*data.append("file", {
        file: file,
        name: file.name,
    });*/
    console.log('image = ', image)
    if (image == "null") {
        data.append('file', image);
    }
    else {
        data.append('file', image, image.name);
    }
    
    Object.keys(info_image).forEach(key => {
        data.append(key, info_image[key]);
    });
    
    for (var [key, value] of data.entries()) { 
        console.log("key value :"+key, value);
    }
    
    return data;
}

const data = [
    {
        id:0,
        idSpeaker:1,
        message:'Hello Jordan, je commence à faire la page messenger !',
        date: '18:45'
    },
    {
        id:1,
        idSpeaker:2,
        message:'Super, ça rend super bien en plus dis moi !',
        date: '18:45'
    },
    {
        id:2,
        idSpeaker:2,
        message:'Ouais ouais on est là',
        date: '18:45'
    },
    {
        id:3,
        idSpeaker:1,
        message:'Je te remercie de ton soutien',
        date: '18:45'
    },
    {
        id:4,
        idSpeaker:1,
        message:'Super gentil merci !',
        date: '18:45'
    },
    {
        id:5,
        idSpeaker:2,
        message:"C'était cool de parler avec toi",
        date: '18:45'
    },
    {
        id:6,
        idSpeaker:2,
        message:'Aller à plus !',
        date: '18:45'
    }
]

const utilizeFocus = () => {
    const ref = React.createRef()
    const setFocus = () => {ref.current &&  ref.current.focus()}
    
    return {setFocus, ref} 
}



export default class Conversation extends Component {
    
    
    constructor(props) {
        super(props);
        
        this.onChangeInput = this.onChangeInput.bind(this);
        this.onClickSend = this.onClickSend.bind(this);
        this.handlePressEnter = this.handlePressEnter.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        
        this.state = {
            new_name: "",
            msg_writting: "",
            data_messages: [],
        };
        
    }
    
    
    componentDidMount() {
        this.getListMessages();
        // this.input.current.focus()
        // this.inputFocus.setFocus()
        // this.textInput.current.focus();
        // this.nameInput.focus();
    }
    
    getListMessages() {
        var self = this;
        var body = {
            id_user: this.props.currentUser.id,
            id_destinataire: 0,
        };
        http.post("/get_list_messages", body)
        .then(function (response) {
            if(response){
                console.log('response = ', response.data)
                self.setState({
                    data_messages: response.data
                })
                self.scrollToBottom()
            }
        })
        .catch(function (error) {
            console.log('error is ',error);
        });
    }
    
    onChangeInput(e) {
        this.setState({
            msg_writting: e.target.value
        });
    }
    
    onClickImage() {
        console.log('click on image')
    }

    clickOnpictureMessenger() {
        console.log('Affiche en grand limage')
    }
    
    onClickSend() {
        
        var self = this;
        console.log('am i a superuser ? ', this.props.currentUser.isSuperUser)
        let isSuperUser = this.props.currentUser.isSuperUser
        let messageObject = null
        if (isSuperUser) {
            messageObject = {
                id_writter: 0,
                id_receiver: null, // Implémenter la version admin
                message: this.state.msg_writting,
            }
        }
        else {
            messageObject = {
                id_writter: this.props.currentUser.id,
                id_receiver: 0,
                message: this.state.msg_writting,
            }
        }
        if (this.state.msg_writting !== "") {
            console.log('MESSAGE messageObject : ', messageObject)
            var data = createFormData('null', {
                id_writter: this.props.currentUser.id,
                id_receiver: 0,
                message: this.state.msg_writting,
                // id_file: -1,
            })
            
            http.post("/add_message", data)
            .then(function (response) {
                if(response){
                    self.getListMessages();
                    self.setState({
                        msg_writting: ""
                    })
                }
            })
            .catch(function (error) {
                console.log('error is ',error);
            });
        }
    }
    
    onFileChange = event => {
        var self = this;
        // Update the state
        let file =  event.target.files[0]
        let type = getType(file.type)
        
        var data = createFormData(file, {
            id_writter: this.props.currentUser.id,
            id_receiver: 0,
            message: file.name,
        })
        
        http.post("api/add_message", data)
        .then(response => { 
            console.log("upload succes", response);
            self.getListMessages();
            return response
            // this.setState({ photo: null });
        })
        .catch(error => { 
            console.log("upload error", error);
            alert("Upload failed!");
        });
    };
    
    
    displayMessage(item) {
        let dateTab = item.date.split(' ')
        let heure_accurate = dateTab[1];
        let heure = heure_accurate.substr(0,5)
        let dayTab = dateTab[0].split('-')
        let id_message = item.id
        // console.log('id = ', item.id)
        if (item.image !== '/media/null' && id_message > 0) { // Si le message est une photo
            let url_messages_file = "http://localhost:8000" + item.image
            if (item.id_writter == this.props.currentUser.id) { // Affiche user message_picture
                return (
                    <div className="messageContainer myMessageContainer">
                        <div className="row noMargin">
                        <img
                            src={url_messages_file}
                            // alt="profile-img"
                            className="pictureMessage"
                            onClick={this.clickOnpictureMessenger}
                            role="button"
                        />
                        <div class="text-right date"> {heure} </div>
                        <img
                            src={jordan}
                            // alt="profile-img"
                            className="profileMessage"
                            // onClick={this.clickOnMessenger}
                            // role="button"
                        /> 
                        </div>
                    </div>
                )
            }
            else {  // Affiche Admin message_picture
                return (
                    <div className="row messageContainer hisMessageContainer align-items-end">

                        <img
                            src={restituer}
                            // alt="profile-img"
                            className="profileMessage"
                            // onClick={this.clickOnMessenger}
                            // role="button"
                        />
                        <div class="date">  </div>
                        <img
                            src={url_messages_file}
                            // alt="profile-img"
                            className="pictureMessage "
                            onClick={this.clickOnpictureMessenger}
                            role="button"
                        />
                        <div class="text-right date"> {heure} </div>
                    </div>
                )

            }
        }
        else if (item.id_writter == this.props.currentUser.id) { // Si le message est du texte
                return (
                    <div class="row messageContainer myMessageContainer ">
                            <div class="msgDateContainer ">
                                <div class="text-left myMessage breakWord"> {item.message} </div>
                                <div class="text-right date"> {heure} </div>
                            </div>
                        <div class="myPictureContainer justify-content-end">
                                <img
                                        src={jordan}
                                        // alt="profile-img"
                                        // alt="Responsive image"
                                        className=" profileMessage"
                                        // onClick={this.clickOnMessenger}
                                        // role="button"
                                /> 
                        </div>
                    </div>


                //     // <div class="row messageContainer myMessageContainer ">
                //     //     <div class="msgDateContainer ">
                //     //         <div class="text-left myMessage"> {item.message} </div>
                //     //         <div class="text-right date"> {heure} </div>
                //     //     </div>
                //     // </div>
                )
            }
        else if (id_message > 0) {
            let string_long = " Comment tu vas fraté ? si ça besoin d'aide je suis là, juste call me and i'll be there. Apparently, we need to add other things because it's not normal right now ! Comment tu vas fraté ? si ça besoin d'aide je suis là, juste call me and i'll be there. Apparently, we need to add other things because it's not normal right now ! Comment tu vas fraté ? si ça besoin d'aide je suis là, juste call me and i'll be there. Apparently, we need to add other things because it's not normal right now ! Comment tu vas fraté ? si ça besoin d'aide je suis là, juste call me and i'll be there. Apparently, we need to add other things because it's not normal right now ! "
            let strinf_attached = "sssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss"
            // console.log('type string_long : ',typeof string_long)
            console.log('type message : ', typeof item.message)
                return (
                    <div class="row messageContainer hisMessageContainer ">
                
                        <img
                            src={restituer}
                            // alt="profile-img"
                            className="profileMessage"
                            // onClick={this.clickOnMessenger}
                            // role="button"
                        />
                        <div class="msgDateContainer">
                            <div class="hisMessage breakWord"> {item.message} </div>
                            <div class="text-right date"> {heure} </div>
                        </div>
                    </div>

                    // <div class="container">
                    //     <div class="row">
                    //         <div class="col-sm breakWord">
                    //              {/* Comment tu vas fraté ? si ça besoin d'aide je suis là, juste call me and i'll be there. Apparently, we need to add other things because it's not normal right now !
                    //         Comment tu vas fraté ? si ça besoin d'aide je suis là, juste call me and i'll be there. Apparently, we need to add other things because it's not normal right now ! Comment tu vas fraté ? si ça besoin d'aide je suis là, juste call me and i'll be there. Apparently, we need to add other things because it's not normal right now !
                    //         Comment tu vas fraté ? si ça besoin d'aide je suis là, juste call me and i'll be there. Apparently, we need to add other things because it's not normal right now ! */}
                    //         {item.message.toString()}
                    //         {/* {string_long} */}
                    //         </div>
                    //         <div class="col-sm">
                    //         {/* {heure} */} coucou
                    //         </div>
                    //     </div>
                    // </div>
                )
        }
        else { // Display NewDay
            return (
                <div class="newDay">{item.message} </div>
            )
        }
    }
    
    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: 'auto' });
    }
    
    handlePressEnter = (e) => {
        if (e.key === 'Enter') {
            this.onClickSend()
        }
    }
    
    render() {
        
        return (
            // <div className="col-md-12">
            // <div className="card card-container">
            
            <Form
            onSubmit={this.handleUpdate}
            ref={c => {
                this.form = c;
            }}
            >
            <div className="conversation list-messages">
            {this.state.data_messages.map(item => (
                <li key={item.id} className="">
                {this.displayMessage(item)}
                </li>
                ))}
                <div style={{ float:"left", clear: "both" }}
                ref={(el) => { this.messagesEnd = el; }}>
                </div>
            </div>
                <div className="row inputMessenger">
            <label > 
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#0D788F" class="bi bi-images cursor" viewBox="0 0 16 16" 
                >
                {/* <input type="file"
                //  onChange={this.onFileChange}
                hidden/> */}
                <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z"/>
                </svg>
                <input type="file" class="inputMessenger"
                onChange={this.onFileChange}
                hidden/>
            </label>
            
            
            {/* <Input */}
            <textarea className="input-group input textarea col-sm textarea"  
            // style={{ height: null }}
            autoFocus
            ref={c => {
                this.input = c;
            }}
            type="text"
            value={this.state.msg_writting}
            onChange={this.onChangeInput}
            onKeyDown={this.handlePressEnter}
            placeholder='Taper message'
            row="0"
            />
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#0D788F" class="bi bi-cursor-fill cursor" viewBox="0 0 16 16" transform="rotate(45)"
            onClick={this.onClickSend}>
            <path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103z"/>
            </svg>
            </div>
            </Form>
            // </div>
            // </div>
            );
        }
    }
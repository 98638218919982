import '../App.css';
import React, { Component } from "react";
import {Link} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import '../css/nav-bar.css'
import AuthService from "../services/auth.service";
import Contact from "./contact.component"
import {NavDropdown,Navbar,Nav,Container} from  'react-bootstrap';
import LoginPopup from './login-popup.component'
import conceptart from '../images/conceptart.png';

export default class NavBar extends Component{

  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      hasToShow: false,
      pseudoUser: '',
    }
  }

  logOut() {
    AuthService.logout();
  }

  onClickConnexion = (pseudoUser) => {
    console.log('coucou: ', pseudoUser)
    this.setState({
      pseudoUser: pseudoUser
    })
}

  render() {

    return (
      <div className="NavBar">
        
        <Nav collapseOnSelect expand="md" className="navbar navbar-expand-lg fixed-top navbar-light bg-light" id="nav-op">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav"> 
            <div className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link to={"/nos-services"} className="nav-link">
                  Fonctionnement
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/conception"} className="nav-link">
                  Conception
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/nos-realisations"}  className="nav-link">
                  Réalisations
                </Link>
              </li>
            </div>

            <Link to={"/"} className="navbar-brand" >
            <img  src={conceptart} className="img-fluid rounded float-center" alt=""/> 
            </Link>

            {this.props.currentUser || this.state.pseudoUser !== "" ? (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Contact page="nav" />
                </li>
                <li className="nav-item">
                <NavDropdown title={ this.state.pseudoUser === "" ? this.props.currentUser.username 
                :  this.state.pseudoUser} id="basic-nav-dropdown" className="login-user">
                  <NavDropdown.Item href="/profile">Mon Profile</NavDropdown.Item>
                  <NavDropdown.Item href="/mon-projet">Mon Projet</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/" onClick={this.logOut}>Se déconnecter</NavDropdown.Item>
                </NavDropdown>
                </li>
              </div>
            ) : (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Contact page="nav" />
                </li>
                <li className="nav-item">

                  <LoginPopup page="autre" 
                  onConnexion={this.onClickConnexion}
                  text={'Se connecter'} />
                </li>

                <li className="nav-item">
                  <Link to={"/register"} className="nav-link login-user">
                    Sign Up
                  </Link>
                </li>
              </div>
            )}
          </Navbar.Collapse>
          </Nav>
    </div>
    );
  }
}
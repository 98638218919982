import '../App.css';
import '../css/nos-realisations.css';
import React, { Component } from "react";

export default class NosRealisations extends Component{
    render() {
        return (
            <div className="container mt-3">
                <div id="NosRealisations">
                Le monde du bâtiment est en constante mutation tant dans la conception que dans les technologies et techniques de construction.
                Nouvelles ambiances, volumes, aménagement des espaces et du temps.

                Faites vous conseiller pour trouver la meilleur solution.

                Fleure Japonaise : Akina
                Localisation : Levallois Perret
                Un restaurant sur mesure :
                Un restaurant qui passe du Libanais au Japonais entièrement rénové.

                Un style emblématique : l'haussmanien !
                Localisation : Paris, 3ème
                Le charme de cet appartement réveillé grâce à un réagencement complet pour gagner en espace.
                Rénovation complète, les moulures, le parquet...

                Réhabilitation d'un entrepôt en 3 appartements:
                Localisation : Maison-Alfort
                Des matériaux simpleet une finition impecable pour un projet à petit prix et un maximum de rentabilité.
                </div>
            </div>
                             
        )
    }
}
import './App.css';
import React, { Component } from 'react';
//import { Switch, Route, Link } from "react-router-dom";
import { Switch, Route} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
//import Inscription from './components/inscription.component';
//import Accueil from './components/accueil.component';

import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import NavBar from "./components/nav-bar.component";
import Projet from "./components/projet.component";
import NosServices from "./components/nos-services.component";
import Conception from "./components/conception.component";
import NosRealisations from "./components/nos-realisations.component";
import MonProjet from "./components/mon-projet.component";
import BottomBar from "./components/bottom-bar.component"
import Conversation from './components/conversation.component';

//import BoardModerator from "./components/board-moderator.component";
//import BoardAdmin from "./components/board-admin.component";


class  App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      //showModeratorBoard: false,
      //showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        //showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        //showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }
  }

  render() {
    //const { currentUser, showModeratorBoard, showAdminBoard } = this.state;
    const { currentUser} = this.state;
    return (
      <div>
        <NavBar currentUser={currentUser}/>
        <BottomBar currentUser={currentUser}/>
        <div>
          <Switch>
            <Route exact path={["/", "/home"]} component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/projet" component={Projet} />
            <Route exact path="/mon-projet" component={MonProjet} />
            <Route exact path="/nos-services" component={NosServices} />
            <Route exact path="/conception" component={Conception} />
            <Route exact path="/nos-realisations" component={NosRealisations} />
            <Route exact path="/conversation" component={Conversation} />
            <Route path="/user" component={BoardUser} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;

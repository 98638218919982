import http from "../http-common";

class ProjetService {
  create(data) {
    return http.post("/create_projet", data)
      .catch(err => {
        console.log(err);
        throw err;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(data) {
    return http.post("/inscription", data);
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new ProjetService();
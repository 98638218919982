import '../App.css';
import React, { Component } from "react";
import {Link} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import AuthService from "../services/auth.service";
import Contact from "./contact.component"
import {NavDropdown} from  'react-bootstrap';
import LoginPopup from './login-popup.component';
import '../css/bottom-bar.css'
import jordan from '../images/jordan.jpg'
import Conversation from './conversation.component'
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import Input from "react-validation/build/input";


const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };

export default class BottomBar extends Component{

  constructor(props) {
    super(props);
    this.clickOnMessenger = this.clickOnMessenger.bind(this);
    this.onChangeVille = this.onChangeVille.bind(this);

    this.state = {
        isShowingMessages: false
    }
  }

    clickOnMessenger = () => {
        console.log('coucou: ')
        this.setState({
            isShowingMessages: !this.state.isShowingMessages
        })
    }


    onChangeVille(e) {
        // this.setState({
        //     ville: e.target.value
        // });
    }

  render() {
    const { currentUser } = this.props
    if (currentUser != undefined) {
      return (
      <div className="container">
        <nav className="navbar navbar-expand fixed-bottom addMargin" id="">

          {this.props.currentUser || this.state.pseudoUser != "" ? (
              
            <div className=" ml-auto list-test pointerAll rightMargin">
                {this.state.isShowingMessages ? (
                  <li className="messengerContainer">
                    
                  <div className="topConversation"> 
                  
                  <button className="font-weight-bold btn btn-primary-outline exitButton pointerAll"
                  type="button"
                  onClick={this.clickOnMessenger}> X</button>
                  </div>

                  <div className="pictureContainer">
                    <div className="textConceptArt">
                      Assistance Concept Art
                    </div>
                      {/* <img
                          // src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                          src={jordan}
                          alt="profile-img"
                          className="messenger-img-card sortable"
                          onClick={this.clickOnMessenger}
                          role="button"
                      /> */}
                  </div>
                  <Conversation 
                  currentUser={this.props.currentUser}/>

                  </li>
                ) : (
                  <div className="navbar-nav navbar-nav-bottom ml-auto">
                  <li className="pointerAll">
                      <img
                          // src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                          src={jordan}
                          alt="profile-img"
                          className="messenger-img-card sortable"
                          onClick={this.clickOnMessenger}
                          role="button"
                      />
                    <HashLink smooth to={"/#NosServices"} className="nav-link">
                      Assistance Concept Art
                    </HashLink>
                  </li>
            </div>
                )}

            </div>
          ) : (
            <div className="navbar-nav ml-auto">
                
            </div>
          )}
        </nav>
    </div>
  );
}
else {
  return null
}

    }
    
}
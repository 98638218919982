import React, { Component } from "react";
import {Link} from "react-router-dom";
import {Modal} from  'react-bootstrap';
import '../css/contact.css'
import '../css/nav-bar.css'
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import AuthService from "../services/auth.service";

// edfr@gmao.of sicret

const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };

export default class LoginPopup extends Component {
    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.state = {
          show: false,
          email: "",
          password: "",
          loading: false,
          message: "",
          connexionDone: false,
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);

        console.log('Im DANS loging-popup')
    }

    onChangeEmail(e) {
    this.setState({
        email: e.target.value
    });
    }

    onChangePassword(e) {
    this.setState({
        password: e.target.value
    });
    }

    handleLogin(e) {
    e.preventDefault();

    this.setState({
        message: "",
        loading: true
    });

    this.form.validateAll();

    var data = {
        email: this.state.email,
        password: this.state.password
    };

    if (this.checkBtn.context._errors.length === 0) {
        // AuthService.login(date).then(function (response) {
        //     if(response) {
        //                 console.log('Connexion REUSSI: data = ', response)
        //     }
        AuthService.login(data).then(
            (response) => {
                this.setState({ 
                    show: false,
                    loading: false,
                    connexionDone: true,
                });
                this.props.onConnexion(response.username)
            },
            error => {
                const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

                this.setState({
                loading: false,
                message: resMessage
                });
            })
    } else {
        this.setState({
        loading: false
        });
    }
    }

    showModal = () => {

    this.setState({ show: true });
    };

    hideModal = () => {
    this.setState({ show: false });
    };

    componentDidMount() {
        console.log('isShowed ? ', this.state.show)
    }

    render() {
        const { text } = this.props
        // let _hasToShow = hasToShow == null ? false : hasToShow
        
        console.log('this.state.show = ', this.state.show)
        return (
            <div className="LoginPopup">
                {this.props.page ==="nav" ? (
                <Link to={"#"} className="nav-link" data-toggle="modal" onClick={this.showModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16" color="#0D788F">
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                    </svg>
                    <i class="bi bi-telephone" color="#0D788F">     06.07.75.74.35</i>
                </Link>
                ) : ( 
                    <Link className="nav-link colorText"  onClick={this.showModal}>
                        {text}
                    </Link>
                )}
               <>
        
                <Modal show={this.state.show} onHide={this.hideModal} className="ModalContact">
                <Modal.Body>
                    <Form
                        onSubmit={this.handleLogin}
                        ref={c => {
                        this.form = c;
                        }}
                    >
                        <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <Input
                            type="text"
                            className="form-control"
                            name="email"
                            value={this.state.email}
                            onChange={this.onChangeEmail}
                            validations={[required]}
                        />
                        </div>

                        <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <Input
                            type="password"
                            className="form-control"
                            name="password"
                            value={this.state.password}
                            onChange={this.onChangePassword}
                            validations={[required]}
                        />
                        </div>

                        <div className="form-group">
                        <button
                            className="btn btn-primary btn-block"
                            disabled={this.state.loading}
                        >
                            {this.state.loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span>Login</span>
                        </button>
                        </div>

                        {this.state.message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                            {this.state.message}
                            </div>
                        </div>
                        )}
                        <CheckButton
                        style={{ display: "none" }}
                        ref={c => {
                            this.checkBtn = c;
                        }}
                        />
                    </Form>
                </Modal.Body>
                </Modal>
            </> 
            </div>
        );
    }
  }
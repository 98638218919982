import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";
import '../css/profile.css'

import http from "../http-common";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const url_profil_image = "http://localhost:8000/media/"

const getType = fileType => {
  return fileType.split("/")[0]
};

const createFormData = (image, info_image) => { 
  const data = new FormData(); // Nom du fichier qui sera enregistré en BDD
  /*data.append("file", {
      file: file,
      name: file.name,
  });*/
  data.append('image', image, image.name);
  
  Object.keys(info_image).forEach(key => {
      data.append(key, info_image[key]);
  });
  
  for (var [key, value] of data.entries()) { 
    console.log("key value :"+key, value);
   }

  return data;
}


const vusername = value => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vlastname = value => {
    if (value.length < 3 || value.length > 20) {
      return (
        <div className="alert alert-danger" role="alert">
          The lastname must be between 3 and 20 characters.
        </div>
      );
    }
};

const vnumber = value => {
    if (value.length < 9 || value.length > 11) {
      return (
        <div className="alert alert-danger" role="alert">
          Le numéro doit contenir entre 9 et 11 chiffres.
        </div>
      );
    }
};

const vpassword = value => {
  if ((value.length < 0 || value.length > 40) && value.length>0) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

export default class Profile extends Component {

  constructor(props) {
    super(props);

    this.handleUpdate = this.handleUpdate.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeLastname = this.onChangeLastname.bind(this);
    this.onChangeNumber = this.onChangeNumber.bind(this);
    this.onChangeOldPassword = this.onChangeOldPassword.bind(this);
    this.onChangeNewPassword = this.onChangeNewPassword.bind(this);
    this.onChangeProfilImage = this.onChangeProfilImage.bind(this);

    const user = AuthService.getCurrentUser();
    
    this.state = {
      user_id: user.id,
      email: user.email,
      new_name: "",
      new_lastname: "", 
      new_number: "",
      old_password :"",
      new_password: "",
      message: "",
      path_profil_image: "",
      type_input: 'hidden',
      selectedFile: null,
      error_file: 'd-none',


      published: false,
      successful: false,
    };
  }

  componentDidMount() {
    this.getProfile();
  }

  getProfile(){
    var self = this;
    var profilData = {
      id_user: self.state.user_id
    }
    var data = {
      email: self.state.email
    };
    http.post("/get_path_profilImage_user", profilData)
    .then(function (res) {
      if (res) {
        http.post("/get_information_user", data)
        .then(function (response) {
          if(response){
            console.log("response = ", response.data)
            self.setState({new_name:response.data.name});
            self.setState({new_lastname:response.data.lastname});
            self.setState({new_number:response.data.number});  
            self.setState({path_profil_image:res.data.path_profil_image})
          }
        })
        .catch(function (error) {
          console.log('error is ',error);
        });

      }
    })
    .catch(function (error) {
      console.log('error is ',error);
    });
  }

  onChangeName(e) {
    this.setState({
      new_name: e.target.value
    });
  }

  onChangeLastname(e) {
    this.setState({
      new_lastname: e.target.value
    });
  }

  onChangeNumber(e) {
    this.setState({
      new_number: e.target.value
    });
  }

  onChangeOldPassword(e) {
    this.setState({
      old_password: e.target.value
    });
  }
  
  onChangeNewPassword(e) {
    this.setState({
      new_password: e.target.value
    });
  }

  handleUpdate(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    var data = {
      new_name: this.state.new_name,
      new_lastname: this.state.new_lastname,
      new_number: this.state.new_number,
      email: this.state.email,
      old_password: this.state.old_password,
      new_password: this.state.new_password
    };

    console.log('dataToSend = ', data)

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.update_profile(data).then(
        () => {
          this.props.history.push("/profile");
          window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            message: resMessage,
            successful: false
          });
        }
      );
    }
  }

  onChangeProfilImage() {
    console.log('onChangeProfilImage')
    if (this.state.type_input == "hidden") {
      console.log('visible')
      this.setState({
        type_input: "file"
      })
    }
    else {
      console.log('hidden')
      this.setState({
        type_input: "hidden"
      })
    }
  }

  onFileChange = event => {
    
    // Update the state
    this.setState({ selectedFile: event.target.files[0]});
    let file =  event.target.files[0]
    console.log('file = ', file)
    let type = getType(file.type)

    if (type != "image") {
      this.setState({
        error_file: '',
      })
    }
    else {
      this.setState({
        error_file: 'd-none',
      })
      var data = createFormData(file, {
          titre: file.name,
          id_user: this.state.user_id,
      })
      console.log('DANS UPLOADFILE: this.state.user_id = ', this.state.user_id)

      http.post("api/change_profil_image", data)
      .then(response => { 
          console.log("upload succes", response);
          this.setState({
            path_profil_image: response.data.path_profil_image,
            type_input: "hidden"
          })
          alert("Upload success!");
          return response
          // this.setState({ photo: null });
      })
      .catch(error => { 
          console.log("upload error", error);
          alert("Upload failed!");
      });
    } 
  };


  render() {
    return (
      <div className="projet-form">
      <div className="col-md-12">
        <div className="card card-container">
          <label>
            
            <input type="file"  onChange={this.onFileChange} hidden/>
          
            <img
              // src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
              src={url_profil_image + this.state.path_profil_image}
              onClick={this.onChangeProfilImage}
              // alt="profile-img" 
              className="profile-img-card profilePicture"
              role="button"
            />
            

            <div class={this.state.error_file + " error"} >
              Vous devez choisir une image
            </div>
          </label>

          <Form
            onSubmit={this.handleUpdate}
            ref={c => {
              this.form = c;
            }}
          >
            {!this.state.successful && (
              <div>
                
                <div className="form-group">
                    <label htmlFor="new_name">Prénom</label>
                    <Input // ou input dans l'ancien
                      type="text"
                      className="form-control"
                      id="new_name"
                      required
                      value={this.state.new_name}
                      onChange={this.onChangeName}
                      name="new_name"
                      validations={[required, vusername]}
                    />
                  </div>
      
                  <div className="form-group">
                    <label htmlFor="new_lastname">Nom</label>
                    <Input
                      type="text"
                      className="form-control"
                      id="new_lastname"
                      required
                      value={this.state.new_lastname}
                      onChange={this.onChangeLastname}
                      name="new_lastname"
                      validations={[required, vlastname]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="new_number">Téléphone</label>
                    <Input
                      type="text"
                      className="form-control"
                      id="new_number"
                      required
                      value={this.state.new_number}
                      onChange={this.onChangeNumber}
                      name="new_number"
                      validations={[required, vnumber]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="old_password">Ancien Password</label>
                    <Input
                      type="password"
                      className="form-control"
                      id="old_password"
                      value={this.state.old_password}
                      onChange={this.onChangeOldPassword}
                      name="old_password"
                      validations={[vpassword]}
                      placeholder="********"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="new_password">Nouveau Password</label>
                    <Input
                      type="password"
                      className="form-control"
                      id="new_password"
                      value={this.state.new_password}
                      onChange={this.onChangeNewPassword}
                      name="new_password"
                      validations={[vpassword]}
                      placeholder="********"
                    />
                  </div>

                <div className="form-group">
                  <button className="btn btn-outline-dark btn-block choice">Mettre à jour</button>
                </div>
              </div>
            )}

            {this.state.message && (
              <div className="form-group">
                <div
                  className={
                    this.state.successful
                      ? "alert alert-success"
                      : "alert alert-danger"
                  }
                  role="alert"
                >
                  {this.state.message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </div>
      </div>
      </div>
    );
  }
}
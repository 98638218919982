import axios from 'axios';
import authHeader from './auth-header';

// const API_URL = 'https://igzqhcg.cluster030.hosting.ovh.net/api/test/';
const API_URL = 'http://localhost:8080/api/test/';
//const API_URL = 'http://igzqhcg.cluster030.hosting.ovh.net/blog/api';

class UserService {
  getPublicContent() { // pas utile on fera tout sur JS
    return axios.get(API_URL + 'all');
  }

  getUserBoard() { // template pour toutes les fonctions appelées
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getAdminBoard() { // maybe later
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }
}

export default new UserService();

// Cette page est-elle utile ? 
import http from "../http-common";

class AuthService {
  login(data) {
    return http.post("/connexion", data)
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(data) {
    return http.post("/inscription", data)
    .then(response => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    })
    .catch(err => {
      console.log(err);
      throw err;
    });
  }

  update_profile(data) {
    return http.post("/update_profile_user", data);
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();
import http from "../http-common";

class MonProjetService {
    
  
  updateProject(data) {
    return http.post("/update_project", data)
    .catch(err => {
      console.log(err);
      throw err;
    });
  }
  }
  
  export default new MonProjetService();
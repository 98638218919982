import axios from "axios";
import ReactDOM from 'react-dom';
import AuthService from "./services/auth.service";
//import authHeader from './services/auth-header';

// Ajouter ici l'auth key
const http = axios.create({
  // baseURL: "https://igzqhcg.cluster030.hosting.ovh.net/blog/api" ,
  //baseURL: "http://localhost:8000/blog/api" ,
  baseURL: "https://api.conceptart.fr/blog/api",
  headers: {
    "Content-type": "application/json",
  }
});

http.interceptors.request.use(request => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.accessToken) {
    request.headers.Authorization = user.accessToken;
  }else {
    request.headers.Authorization = ``;
  }
  return request;
});

export default http;
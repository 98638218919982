// import '../App.css';
import '../css/projet.css'
import React, { Component } from "react";
import Form from "react-validation/build/form";
import {Link} from "react-router-dom";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service"
import LoginPopup from "./login-popup.component"
import ProjetService from "../services/project.service";


const options = [
    { label: 'Réaménager votre intérieur', value: 'reamenager' },
    { label: 'Réaliser une surélévation ou extension', value: 'extension' },
    { label: 'Réaliser une nouvelle construction', value: 'construction' },
    { label: 'Etre simplement conseillé', value: 'conseille' },
  ];

const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
            This field is required!
            </div>
            );
        }
    };
    
    export default class Projet extends Component{
        
        componentDidMount() {
            const user = AuthService.getCurrentUser();

            if (user) {
                this.setState({
                    id_user: user.id,
                    //showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
                    //showAdminBoard: user.roles.includes("ROLE_ADMIN"),
                });
            }
        }
        
        constructor(props) {
            super(props);


            this.handleCreationProject = this.handleCreationProject.bind(this);
            this.handleConnection= this.handleConnection.bind(this);
            this.onChangeBesoin = this.onChangeBesoin.bind(this);
            this.onChangePrecision= this.onChangePrecision.bind(this);
            this.onChangePlan = this.onChangePlan.bind(this);
            this.onChangeCoupe = this.onChangeCoupe.bind(this);
            this.onChangeElevation= this.onChangeElevation.bind(this);
            this.onChangeImage3d= this.onChangeImage3d.bind(this);
            this.onChangeSurface= this.onChangeSurface.bind(this);
            this.onChangePermisConstruire = this.onChangePermisConstruire.bind(this);
            this.onChangeDeclarationPreabable= this.onChangeDeclarationPreabable.bind(this);
            this.onChangeOuvertureErp= this.onChangeOuvertureErp.bind(this);
            this.onChangeVille= this.onChangeVille.bind(this);
            this.onChangeCodePostal= this.onChangeCodePostal.bind(this);
            this.onChangeAdresse= this.onChangeAdresse.bind(this);
            this.onChangeAutres= this.onChangeAutres.bind(this);
            this.showConnexionPopUp = this.showConnexionPopUp.bind(this)

            const {type} = props.location.state
            
            this.state = {
                id_user: undefined,
                type : type,
                besoin: "Réaménager votre intérieur",
                precision: "Au tout début", 
                plan: false,
                coupe: false, 
                elevation :false,
                image3d: false,
                surface: 0,
                permis_construire: false,
                declaration_prealable: false,
                ouverture_erp: false,
                ville: "",
                code_postal:"",
                adresse: "",
                autres: "",
                statut: "Not start",
                
                
                published: false,
                successful: false,
                showConnexionPopUp: false,
            };

            this.choiceText = "choice"
        }
        
        onChangeBesoin(e) {
            this.setState({
                besoin: e.target.value
            });
        }
        
        onChangePlan = () => {
            this.setState(initialState => ({
                plan: !initialState.plan,
            }));
        }
        
        onChangePrecision(e) {
            this.setState({
                precision: e.target.value
            });
        }
        
        onChangeCoupe = () => {
            this.setState(initialState => ({
                coupe: !initialState.coupe,
            }));
        }
        
        onChangeElevation = () => {
            this.setState(initialState => ({
                elevation: !initialState.elevation,
            }));
        }
        
        onChangeImage3d = () => {
            this.setState(initialState => ({
                image3d: !initialState.image3d,
            }));
        }
        
        onChangeSurface(e) {
            this.setState({
                surface: e.target.value
            });
        }
        
        onChangePermisConstruire = () => {
            this.setState(initialState => ({
                permis_construire: !initialState.permis_construire,
            }));
        }
        
        onChangeDeclarationPreabable = () => {
            this.setState(initialState => ({
                declaration_prealable: !initialState.declaration_prealable,
            }));
        }
        
        onChangeOuvertureErp = () => {
            this.setState(initialState => ({
                ouverture_erp: !initialState.ouverture_erp,
            }));
        }
        
        onChangeVille(e) {
            this.setState({
                ville: e.target.value
            });
        }
        
        onChangeCodePostal(e) {
            this.setState({
                code_postal: e.target.value
            });
        }
        
        onChangeAdresse(e) {
            this.setState({
                adresse: e.target.value
            });
        }
        
        onChangeAutres(e) {
            this.setState({
                autres: e.target.value
            });
        }
        
        handleCreationProject(e) {
            console.log('JE SUIS LAAAAAAAAA, this.state.id_user = ', this.state.id_user)
            e.preventDefault();
            this.setState({
                message: "",
                successful: false
            });
            
            var data = {
                id_user : this.state.id_user,
                type : this.state.type,
                besoin: this.state.besoin,
                precision: this.state.precision,
                plan: this.state.plan,
                coupe: this.state.coupe,
                elevation: this.state.elevation,
                image3d: this.state.image3d,
                surface: this.state.surface,
                permis_construire: this.state.permis_construire,
                declaration_prealable: this.state.declaration_prealable,
                ouverture_erp: this.state.ouverture_erp,
                ville: this.state.ville,
                code_postal: this.state.code_postal,
                adresse: this.state.adresse,
                autres: this.state.autres,
                statut: this.state.statut
            };
            
            if (this.checkBtn.context._errors.length === 0 &&  this.state.id_user !== undefined) {
                console.log('ON est RENTREEE')
                ProjetService.create(data).then(
                    () => {
                        this.props.history.push("/profile");
                        window.location.reload();
                    },
                    error => {
                        const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                            error.message ||
                            error.toString();
                            
                            this.setState({
                                message: resMessage,
                                successful: false
                            });
                        }
                        );
                    }
                    else {
                        this.setState({
                            showConnexionPopUp: true,
                        })
                        console.log('login = ', this.loginPopup)
                        this.loginPopup.showModal()
                    }
                }
                
                handleConnection(e) {
                    e.preventDefault();
                    if (this.checkBtn.context._errors.length === 0) {
                        setTimeout(() => {
                            this.setState({
                                id_user: AuthService.getCurrentUser().id
                            });
                        }, 3000);
                    }
                }


                test = () => {
                    console.log('coucouS')
                };
                
                selectpickerChoice = () => {
                    console.log('heyyyyyy')
                }

                showConnexionPopUp = () => {
                    console.log('show it baby !')
                    this.setState({
                        hasToShow: !this.state.hasToShow
                    })
                }

                onClickConnexion = (pseudoUser) => {
                    console.log('coucou: ', pseudoUser)
                }

                render() {
                    return (
                    <div className="container mt-2 projet">
                    <Form
                        onSubmit={this.handleCreationProject}
                        ref={c => {
                        this.form = c;
                        }}
                    >
                        {!this.state.successful && (
                        <div className="projet-form">

                            <div class="title rounded">
                                Votre projet :
                            </div>

                            <div class="miniSpace"></div>

                            <div class="row">
                        <div className="form-group  col-lg-6">
                          <label class="fontFamily">
                          Vous avez le projet de :
                            <select class="selectpicker custom-select" 
                            value={this.state.value} onChange={this.onChangeBesoin}>
                                <option>Réaménager votre intérieur</option>
                                <option>Réaliser une surélévation ou extension</option>
                                <option>Réaliser une nouvelle construction</option>
                                <option>Etre simplement conseillé</option>
                            </select>
                          </label>
                        </div>
                          
                        <div className="form-group col-lg-6">
                          <label class="fontFamily">
                          Où en êtes vous ? :
                              <select class="selectpicker custom-select" 
                                value={this.state.value} onChange={this.onChangePrecision}>
                                  <option>Au tout début</option>
                                  <option>Vous avez déjà une idée</option>
                                  <option>Vous savez exactement ce que vous souhaitez</option>
                              </select>
                          </label>
                        </div>
                      </div>


                      <div class="space"></div>

                            <div class="title rounded">
                                Vous désirez :
                            </div>

                          <div class="row marginHorizontal">
                            {/* <div className="form-group col-lg-3"> */}
                            <div class="text-center col-sm" className="col-sm nopaddingWithMargin">
                                <button className= {this.state.plan ? "btn btn-outline-dark btn-block choice" : "btn btn-outline-dark btn-block"}
                                type="button"
                                onClick={this.onChangePlan}
                                >
                                Plan
                                </button>
                            </div>
    
                     <div className="col-sm nopaddingWithMargin">
                                <button className= {this.state.coupe ? "btn btn-outline-dark btn-block choice" : "btn btn-outline-dark btn-block"}
                                type="button"
                                onClick={this.onChangeCoupe}
                                >
                                Coupe
                                </button>
                            </div>
    
                            <div className="col-sm nopaddingWithMargin">
                                <button className= {this.state.elevation ? "btn btn-outline-dark btn-block choice pl-0 pr-0" : "btn btn-outline-dark btn-block pl-0 pr-0"}
                                type="button"
                                onClick={this.onChangeElevation}
                                >
                                Elévation
                                </button>
                            </div>
    
                            <div className="col-sm nopaddingWithMargin">
                                <button className= {this.state.image3d ? "btn btn-outline-dark btn-block choice" : "btn btn-outline-dark btn-block"}
                                type="button"
                                onClick={this.onChangeImage3d}
                                >
                                Image 3D
                                </button>
                            </div>
                          </div>
    
                          <div class="row marginHorizontal">
                            <div className="col-sm nopaddingWithMargin">
                                <button className= {this.state.permis_construire ? "btn btn-outline-dark btn-block choice" : "btn btn-outline-dark btn-block"}
                                type="button"
                                onClick={this.onChangePermisConstruire}
                                >
                                Permis de construire
                                </button>
                            </div>
    
                            <div className="col-sm nopaddingWithMargin">
                                <button className= {this.state.declaration_prealable ? "btn btn-outline-dark btn-block choice" : "btn btn-outline-dark btn-block"}
                                type="button"
                                onClick={this.onChangeDeclarationPreabable}
                                >
                                Déclaration préalable
                                </button>
                            </div>
    
                            <div className="col-sm nopaddingWithMargin">
                                <button className= {this.state.ouverture_erp ? "btn btn-outline-dark btn-block choice" : "btn btn-outline-dark btn-block"}
                                type="button"
                                onClick={this.onChangeOuvertureErp}
                                >
                                Ouverture ERP
                                </button>
                            </div>
                          </div>
                          
                          <div class="space"></div>
                          <div class="space"></div>

                          <div class="title rounded">
                                Lieu et surface :
                        </div>
                            
                          <div class="miniSpace"></div>

                          <div class="row marginHorizontal">
    
                            <div className="form-group col-sm">
                                        <Input
                                        type="text"
                                        className="form-control miniMargin"
                                        id="ville"
                                        required
                                        value={this.state.ville}
                                        onChange={this.onChangeVille}
                                        name="ville"
                                        placeholder="Ville"
                                        />


                                        <Input
                                        type="text"
                                        className="form-control miniMargin"
                                        id="adresse"
                                        required
                                        value={this.state.adresse}
                                        onChange={this.onChangeAdresse}
                                        name="adresse"
                                        placeholder="Adresse"
                                        />
                            </div>
    
                            <div className="form-group col-sm">
                                        <Input
                                        type="text"
                                        className="form-control miniMargin"
                                        id="code_postal"
                                        required
                                        value={this.state.code_postal}
                                        onChange={this.onChangeCodePostal}
                                        name="code_postal"
                                        placeholder="Code postal"
                                        />

                                        <Input
                                        // type="number"
                                        type="tel"
                                        step="0.01"
                                        pattern="[0-9]*"
                                        className="form-control miniMargin"
                                        id="surface"
                                        required
                                        value={this.state.surface}
                                        onChange={this.onChangeSurface}
                                        name="surface"
                                        placeholder="Surface"
                                        />
                            </div>

                          </div>

                          <div class="space"></div>

                          <div className="form-group col-sm">
                            <textarea className="textarea col-sm" 
                            value={this.state.value}
                            onChange={this.onChangeAutres}
                            placeholder="Un commentaire ? Ex: Nous souhaitons étendre notre logement pour accueillir une petite nouvelle dans la famille. Nous souhaitons une pièce avec beaucoup de luminosité, une salle de bain, et en profiter pour refaire la cuisine si ça rentre dans le budget."
                                        
                            rows="5"/>
                            </div>
                          <div className="form-group">
                            <button className="btn btn-primary btn-block btn-concepart">Créer un nouveau projet</button>
                          </div>

                          <div class="space"></div>

                        </div>
                        )}
    
                        {this.state.message && (
                        <div className="form-group">
                            <div
                            className={
                                this.state.successful
                                ? "alert alert-success"
                                : "alert alert-danger"
                            }
                            role="alert"
                            >
                            {this.state.message}
                            </div>
                        </div>
                        )}
                        <CheckButton
                        style={{ display: "none" }}
                        ref={c => {
                            this.checkBtn = c;
                        }}
                        />
                    </Form>
                    <Form
                        onSubmit={this.handleConnection}
                        ref={c => {
                        this.form = c;
                        }}
                    >
                    <LoginPopup page="autre" 
                    onConnexion={this.onClickConnexion}
                    text=""
                    ref={c => {
                        this.loginPopup = c;
                    }}/>
                      <CheckButton
                      style={{ display: "none" }}
                      ref={c => {
                          this.checkBtn = c;
                      }}
                      />
                    </Form>
                </div>
                        )
                    }
                }
                
import '../App.css';
import '../css/conception.css';
import React, { Component } from "react";

import demarches from '../images/demarches.png';
import amenagement from '../images/amenagement.png';
import construction from '../images/construction.png';

import icon from '../images/ms-icon-310x310.png';
import conceptart from '../images/conceptart.png';


export default class Conception extends Component{
    render() {
        return (
            <div className="container mt-3">
                <div id="Conception">
                    <h2><span className="title-conception"> 3 Domaines</span> d'expertise privilégié : </h2>
                    <div className="bloc-conception">
                        <div className="number">01</div> Nous réalisons l'ensemble de vos <strong>démarches administratives : </strong>
                        <div className="row conception-block" >
                            <div className="col-md-4 text-center">
                                <img  src={demarches} className="rounded float-left" alt="Démarches"/>
                            </div>
                            <div className="col-md-8 list-block">
                                <ul>
                                    <li>Permis de construire (PC)</li>
                                    <li>Ouverture d'ERP (AT)</li>
                                    <li>Déclaration Préalable (DP)</li>
                                    <li>Permis d'aménagement</li>
                                    <li>Déclaration de travaux</li>
                                    <li>Dossier de consultation des entreprises (DCE)</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="bloc-conception">
                        <div className="number">02</div> Nous étudions vos espaces pour vous proposer un <strong>aménagement spécifique : </strong>
                        <div className="row conception-block" >
                            <div className="col-md-4 text-center">
                                <img  src={amenagement} className="rounded float-left" alt="Amenagement"/>
                            </div>
                            <div className="col-md-8 list-block">
                                <ul>
                                    <li>Aménagement : <ul><li>- bancaire</li>
                                    <li>- restaurant</li>
                                    <li>- hôtelerie</li>
                                    <li>- tertiaire</li></ul></li>
                                    <li>Rénovation d'appartement</li>
                                    <li>Déclaration d'intérieur/extérieur</li>
                                    <li>Tendances décoration</li>
                                    <li>Perspectives et vues 3D</li>
                                    <li>Amenagement sur-mesure</li>
                                </ul>
                            </div>
                        </div>
                    
                    </div>

                    <div className="bloc-conception">
                        <div className="number">03</div> Nous concrétisons vos <strong>projets de construction :</strong>
                        <div className="row conception-block" >
                            <div className="col-md-4 text-center">
                                <img  src={construction} className="rounded float-left" alt="Construction"/>
                            </div>
                            <div className="col-md-8 list-block">
                                <ul>
                                    <li>Surélévation d'immeuble</li>
                                    <li>Construction neuve</li>
                                    <li>Extension</li>
                                    <li>Surélévation de maison</li>
                                    <li>Construction de garage</li>
                                    <li>Changement d'usage</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="end-conception">
                        Un service complet "Clé en main" <br/>
                        Du conseil à la remise des clés en passant par l'architecture d'intérieur, le design global, la distribution de mobilier et le décor.
                    </div>
                    <div className="row signature justify-content-md-center">
                        <div className="col-md-2 logo-signature">
                            <img  src={icon} className="rounded float-left" alt="icone"/> 
                            <img  src={conceptart} className="rounded float-left" alt="Concept Art"/> 
                        </div>
                        <div className="col-md-7 text-signature">
                            <div className="col-md-5 align-self-start"><em>Contactez nous...</em></div>
                            <div className="col-md-7 align-self-end"><em>Nous nous occupons du restes !</em></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
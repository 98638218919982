import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import AuthService from "../services/auth.service";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = value => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = value => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vlastname = value => {
    if (value.length < 3 || value.length > 20) {
      return (
        <div className="alert alert-danger" role="alert">
          The lastname must be between 3 and 20 characters.
        </div>
      );
    }
};

const vnumber = value => {
    if (value.length < 9 || value.length > 11) {
      return (
        <div className="alert alert-danger" role="alert">
          The number must be between 9 and 11 integer.
        </div>
      );
    }
};

const vpassword = value => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeLastname = this.onChangeLastname.bind(this);
    this.onChangeNumber = this.onChangeNumber.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    //this.saveUser = this.saveUser.bind(this);
    //this.newUser = this.newUser.bind(this);

    this.state = {
        name: "",
        lastname: "", 
        number: "",
        email: "", 
        password :"",
        message: "",

        published: false,
        successful: false
    };
  }

  onChangeName(e) {
    this.setState({
        name: e.target.value
    });
  }

  onChangeLastname(e) {
    this.setState({
      lastname: e.target.value
    });
  }

  onChangeNumber(e) {
    this.setState({
      number: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    var data = {
        name: this.state.name,
        lastname: this.state.lastname,
        number: this.state.number,
        email: this.state.email,
        password: this.state.password
    };

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.register(data).then(
        () => {
          this.props.history.push("/profile");
          window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            message: resMessage,
            successful: false
          });
        }
      );
    }
  }

  render() {
    return (
      <div className="col-md-12">
        <div className="card card-container">
          <img
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            alt="profile-img"
            className="profile-img-card"
          />

          <Form
            onSubmit={this.handleRegister}
            ref={c => {
              this.form = c;
            }}
          >
            {!this.state.successful && (
              <div>

                <div className="form-group">
                    <label htmlFor="name">Prénom</label>
                    <Input // ou input dans l'ancien
                      type="text"
                      className="form-control"
                      id="name"
                      required
                      value={this.state.name}
                      onChange={this.onChangeName}
                      name="name"
                      validations={[required, vusername]}
                    />
                  </div>
      
                  <div className="form-group">
                    <label htmlFor="lastname">Nom</label>
                    <Input
                      type="text"
                      className="form-control"
                      id="lastname"
                      required
                      value={this.state.lastname}
                      onChange={this.onChangeLastname}
                      name="lastname"
                      validations={[required, vlastname]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="number">Téléphone</label>
                    <Input
                      type="text"
                      className="form-control"
                      id="number"
                      required
                      value={this.state.number}
                      onChange={this.onChangeNumber}
                      name="number"
                      validations={[required, vnumber]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Mail</label>
                    <Input
                      type="text"
                      className="form-control"
                      id="email"
                      required
                      value={this.state.email}
                      onChange={this.onChangeEmail}
                      name="email"
                      validations={[required, email]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Input
                      type="password"
                      className="form-control"
                      id="password"
                      required
                      value={this.state.password}
                      onChange={this.onChangePassword}
                      name="password"
                      validations={[required, vpassword]}
                    />
                  </div>

                <div className="form-group">
                  <button className="btn btn-primary btn-block">Sign Up</button>
                </div>
              </div>
            )}

            {this.state.message && (
              <div className="form-group">
                <div
                  className={
                    this.state.successful
                      ? "alert alert-success"
                      : "alert alert-danger"
                  }
                  role="alert"
                >
                  {this.state.message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </div>
      </div>
    );
  }
}
import '../css/nos-services.css'
import React, { Component } from "react";
import comprendre from '../images/comprendre.png';
import concevoir from '../images/concevoir.png';
import restituer from '../images/restituer.png';
import organiser from '../images/organiser.png';
import realiser from '../images/realiser.png';

import icon from '../images/ms-icon-310x310.png';
import conceptart from '../images/conceptart.png';


export default class NosServices extends Component{
    render() {
        return (
            <div className="container mt-3">
                <div id="NosServices">
                    
                    <div className="row service-block" >
                        <div className="col-md-4 text-center">
                            <img  src={comprendre} className="rounded float-left" alt="Comprendre"/>
                        </div>
                        <div className="col-md-8 service-left-bloc">
                            <p>
                                <h2><div className="number">01 </div>Vous comprendre</h2>
                                Cette première étape va nous permettre de comprendre vos attentes et d'avoir une vision de votre projet.
                                C'est dans cette phase d'investigation que nous menons en parallèle des audits réglementaires, techniques et architecturaux de 
                                votre site ou du site ciblé dans le cas d'une future installation.
                            </p>
                        </div>
                    </div>
                    <div className="row service-block">
                        <div className="col-md-8 service-right-bloc">
                            <p>
                                <h2>Conception<div className="number"> 02</div></h2>
                                C'est la phase du du dessin informatique, de la 3D et des maquettes. Cette phase structure entièrement le projet en accord 
                                avec vos idées et vos besoins.
                                Au fur et à mesure de l'avancement de cette phase, nous échangeons avec vous afin de mettre à jour les pièces graphiques
                                selon vos souhaits.
                            </p>
                        </div>
                        <div className="col-md-4 text-center">
                            <img  src={concevoir} className="rounded float-right" alt="concevoir"/>
                        </div>
                    </div>
                    <div className="row service-block">
                        <div className="col-md-4 text-center">
                            <img  src={restituer} className="rounded float-left" alt="restituer"/>
                        </div>
                        <div className="col-md-8 service-left-bloc">
                            <p>
                                <h2><div className="number">03 </div>Restitution</h2>
                                Une fois l'ensemble des documents réalisés, une denière vérification est faite avec vous puis en fonction de votre projet :<br />
                                - Les documents d'aménagement vous sont restitués. <br />
                                - Le permis de construire ou déclaration préalable est déposé en mairie.
                            </p>
                        </div>
                    </div>
                    <div className="row service-block">
                        <div className="col-md-8 service-right-bloc">
                            <p>
                                <h2>Nous préparons votre chantier<div className="number"> 04</div></h2>
                                Nous organisons la date de démarrage des travaux avec vous. Nous vous transmettons un programme architectural et technique
                                ainsi qu'un planning pour réaliser votre aménagement.
                            </p>
                        </div>
                        <div className="col-md-4 text-center">
                            <img  src={organiser} className="rounded float-right" alt="organiser"/>
                        </div>
                        
                    </div>
                    <div className="row service-block">
                        <div className="col-md-4 text-center">
                            <img  src={realiser} className="rounded float-left" alt="realiser"/>
                        </div>
                        <div className="col-md-8 service-left-bloc">
                            <p>
                                <h2><div className="number">05 </div>Nos équipes interviennent</h2>
                                Les travaux commencent avec sérénité. Des réunions de suivi de chantier sont organisées pour vous permettre d'être
                                au courant des différentes phases du projet.
                            </p>
                        </div>
                    </div>
                    <div className="row signature justify-content-md-center">
                        <span className="col-md-12 end-services"><em>De l'étude de vos espaces à l'optimisation de leurs potentiels..</em></span>
                        <span className="col-md-12 end-services2">Chaque projet est unique, l'équipe Concept Art vous proposent ses meilleurs solutions architecturales pour répondre au mieux a votre environnement.</span>
                        <div className="col-md-2 logo-signature">
                            <img  src={icon} className="rounded float-left" alt="icone"/> 
                            <img  src={conceptart} className="rounded float-left" alt="Concept Art"/> 
                        </div>
                        <div className="col-md-8 text-signature">
                            <div className="col-md-5 align-self-start"><em>Contactez nous...</em></div>
                            <div className="col-md-7 align-self-end"><em>Nous nous occupons du restes !</em></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
import React, { Component } from "react";
import '../css/home.css'
import {Link} from "react-router-dom";


//import UserService from "../services/user.service";
import Contact from "./contact.component"

//slider
import BackgroundSlider from 'react-background-slider'
import image1 from '../images/home1.png'
import image2 from '../images/home2.png'
import image3 from '../images/home3.png'
import image4 from '../images/home4.png'




export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
    
  }

  test = () => {
    console.log('coucouS')
    };

  render() {
    return (
      //changer le point par mieux
      <div className="container mt-3">
        <div className="bg d-none d-lg-block">
          .<BackgroundSlider
          images={[image1, image2, image3, image4]}
          duration={10} transition={2} />
            <div className="row">
              <div className="col-lg-6"> 
                <p className = "jumbotron home-left-bloc-one">
                  <h2>De l'architecture haut de gamme ! </h2>
                  <footer className="blockquote-footer">&nbsp;&nbsp;Qui rentre dans votre budget -</footer>
                </p>
                <p className = "jumbotron home-left-bloc-one-bis"> - Vos projets achevés avec des finitions dignes de ce nom -<br />
                    Essayez nous ! ou au moins <Contact page="home" /> 
                </p>
              </div>
              <div className="col-lg-6 text-center">
                <p className = "jumbotron home-right-bloc-one">
                  <h4>Laissez-vous guidez, expliquez nous rapidement votre projet :</h4> <br />
                  <Link to={{ pathname: '/projet', state: { type: 'Professionnel'} }} className="btn btn-outline-dark btn-block">
                  Vous êtes un professionnel</Link><br />
                  <Link to={{ pathname: '/projet', state: { type: 'Particulier'} }} className="btn btn-outline-dark btn-block">
                  Vous êtes un particulier</Link><br />
                  <button type="button" className="btn btn-outline-dark btn-block" onClick={this.test}>Vous avez déjà un devis
                  et souhaitez le comparer avec nos offres ConceptArt</button> <br />
                </p> 
              </div>
            </div>
        </div>
      </div>
    );
  }
}
import React, { Component } from "react";
import AuthService from "../services/auth.service";
import MonProjetService from "../services/mon-projet.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import '../css/mon-projet.css'
import {Link} from "react-router-dom";
import CheckButton from "react-validation/build/button";
import { PencilFill,FileEarmarkTextFill, PlusCircle,
Trash } from 'react-bootstrap-icons';

import http from "../http-common";

//http://localhost:8000/media/post_images/test.txt

const createFormData = (file, info_document) => { 
  const data = new FormData(); // Nom du fichier qui sera enregistré en BDD
  /*data.append("file", {
      file: file,
      name: file.name,
  });*/
  data.append('file', file, file.name);
  
  Object.keys(info_document).forEach(key => {
      data.append(key, info_document[key]);
  });
  
  for (var [key, value] of data.entries()) { 
    console.log("key value :"+key, value);
   }

  return data;
}

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const vnumber = value => {
  if (value.length < 1 || value.length > 4) {
    return (
      <div className="alert alert-danger" role="alert">
        La suface doit être entre 0 et 9999 m2.
      </div>
    );
  }
};

const vtype = value => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The type must be between 3 and 25 characters.
      </div>
    );
  }
};

const vinfo = value => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The type must be between 3 and 255 characters.
      </div>
    );
  }
};

const URL_API="https://api.conceptart.fr"
//const URL_API="http://localhost:8000"

export default class MonProjet extends Component {
    constructor(props) {
      super(props);
      this.handleDelDoc = this.handleDelDoc.bind(this);
      this.onChangeIdProject = this.onChangeIdProject.bind(this);
      this.showEco = this.showEco.bind(this);
      this.showEsq = this.showEsq.bind(this);
      this.showBefPro = this.showBefPro.bind(this);
      this.showPrepBuil = this.showPrepBuil.bind(this);
      this.onChangeAdress = this.onChangeAdress.bind(this);
      this.onChangeType = this.onChangeType.bind(this);
      this.onChangeSurface = this.onChangeSurface.bind(this);
      this.onChangeInfo = this.onChangeInfo.bind(this);
      this.handleUpdateProject = this.handleUpdateProject.bind(this);
  
      const user = AuthService.getCurrentUser();
      
  
      this.state = {
        user_id: user.id,
        id_project : undefined,
        list_projects: [],
        list_devis: [],
        list_documents: [],
        list_chantiers: [],
        new_adress: "",
        new_type: "",
        new_surface: 0,
        new_info: "",
        message: "",
        show_economy: false,
        show_esquisse: false,
        show_before_project: false,
        show_prep_building: false,
        successful: false,
      };
    }
  
    componentDidMount() { 
      this.getProjects();     
    }
  
    getProjects(){
      var self = this;
      var data = {
        user_id: self.state.user_id 
      };
      http.post("/get_list_projects", data)
      .then(function (response) {
        if(response) {
            self.setState({list_projects:response.data});
            //console.log("self.list_projects: "+self.state.list_projects[1].id)
        }
      })
      .catch(function (error) {
        console.log('error is ',error);
      });
    }

    getDocuments(){
      var self = this;
      var data = {
        id_project: self.state.id_project 
      };
      if (data.id_project){
        http.post("/get_list_documents", data)
        .then(function (response) {
          if(response) {
              self.setState({list_documents:response.data});
          }
        })
        .catch(function (error) {
          console.log('error is ',error);
        });
      }
      
    }

    getInfoProject(){
      var self = this;
      var data = {
        id_project: self.state.id_project 
      };
      if (data.id_project) {
        self.state.list_projects.filter(projects => projects.id_project = data.id_project).map(
          project => (
            this.setState({
              new_adress: project.adresse,
              new_type: project.type,
              new_surface: project.surface,
              new_info: project.info_supplementaire
            })
        ))
        http.post("/get_list_documents", data)
        .then(function (response) {
          if(response) {
              self.setState({list_documents:response.data});
          }
        })
        .catch(function (error) {
          console.log('error is ',error);
        });
      };
    }

    onFileChange =  (event, type) => {
    
      // Update the state
      console.log('DANS UPLOADFILE: id_project = ', this.state.id_project)
      var data = createFormData(event.target.files[0], {
          titre: event.target.files[0].name,
          id_user: this.state.user_id,
          id_project: this.state.id_project,
          type: type,
      })
      http.post("api/upload_document", data)
      .then(response => { 
          console.log("upload succes", response);
          alert("Upload success!");
          this.getDocuments();
          //this.setState({ selectedFile: null});
          //window.location.reload();
          //return response
          // this.setState({ photo: null });
      })
      .catch(error => { 
          console.log("upload error", error);
          alert("Upload failed!");
      });
    
    };

    onChangeIdProject(e) {
      console.log(e.target.value)
        this.setState({
            id_project: e.target.value
        }, () => {this.getInfoProject();});
    }


    handleDelDoc(id_doc) {
      var data= {id : id_doc}
      http.post("api/delete_file", data)
      .then(response => { 
          console.log("Document delete", response);
          alert("Document supprimé !");
          this.getDocuments();
          //this.setState({ selectedFile: null});
          //window.location.reload();
          //return response
          // this.setState({ photo: null });
      })
      .catch(error => { 
          console.log("delete error", error);
          alert("Impossible de supprimer le document!");
      });

    }

    showEco = () => {
      this.setState({ show_economy:  !this.state.show_economy });
    };

    showEsq = () => {
      this.setState({ show_esquisse:  !this.state.show_esquisse });
    };

    showBefPro = () => {
      this.setState({ show_before_project:  !this.state.show_before_project });
    };

    showPrepBuil = () => {
      this.setState({ show_prep_building:  !this.state.show_prep_building });
    };

    onChangeAdress(e) {
      this.setState({
        new_adress: e.target.value
      });
    }

    onChangeType(e) {
      this.setState({
        new_type: e.target.value
      });
    }

    onChangeSurface(e) {
      this.setState({
        new_surface: e.target.value
      });
    }

    onChangeInfo(e) {
      this.setState({
        new_info: e.target.value
      });
    }
    
    handleUpdateProject(e) {
      e.preventDefault();
  
      this.setState({
        message: "",
        successful: false
      });
  
      this.form.validateAll();
  
      var data = {
        id_project : this.state.id_project,
        new_adress: this.state.new_adress,
        new_type: this.state.new_type,
        new_info: this.state.new_info,
        new_surface: this.state.new_surface
      };
  
      if (this.checkBtn.context._errors.length === 0) {
        MonProjetService.updateProject(data).then(
          () => {
            this.props.history.push("/mon-projet");
            window.location.reload();
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
  
            this.setState({
              message: resMessage,
              successful: false
            });
          }
        );
      }
    }
  
    render() {
  
      return (
        <div className="col-md-12">
          <div className="container mt-2 monprojet">
              {this.state.id_project ? (
                <div>
                {this.state.list_projects.filter(projects => projects.id_project = this.state.id_project).map(
                  project => (
                    <div className = "project_details">
                      <div className ="jumbotron project-desc">
                        <Form
                          onSubmit={this.handleUpdateProject}
                          ref={c => {
                            this.form = c;
                          }}
                        >
                          {!this.state.successful && (
                          <div>

                            <h4 className="title_project">Information du projet :</h4>
                            <div class="row">
                              <div class="col">
                              Adresse :
                              <div className="form-group has-feedback has-feedback-left">
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="new_adress"
                                    required
                                    value={project.adresse}
                                    onChange={this.onChangeAdress}
                                    name="new_adress"
                                  /> <PencilFill />
                              </div>
                              </div>

                              <div class="col">
                              Type du bien :
                                <div className="form-group">
                                  <Input
                                      type="text"
                                      className="form-control"
                                      id="new_type"
                                      required
                                      value={project.type}
                                      onChange={this.onChangeType}
                                      name="new_type"
                                    /> <button>
                                    <PencilFill />  
                                  </button>
                                </div>
                              </div>

                              <div class="col">
                              Surface :
                                <div className="form-group">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="new_surface"
                                    required
                                    value={project.surface} 
                                    onChange={this.onChangeSurface}
                                    name="new_surface"
                                  /> <button>
                                  <PencilFill />
                                </button>
                                </div>
                              </div>
                            </div>

                            Informations supplémentaires : <br />
                            <div className="form-group">
                              <Input
                                type="text"
                                className="form-control"
                                id="new_info"
                                required
                                value={project.info_supplementaire} 
                                onChange={this.onChangeInfo}
                                name="new_info"
                              /> <button>
                              <PencilFill />
                              </button>
                            </div>
                            <div className="form-group">
                              <button className="btn btn-outline-dark btn-block choice">Mettre à jour</button>
                            </div>

                          </div>)}
                          {this.state.message && (
                          <div className="form-group">
                            <div
                              className={
                                this.state.successful
                                  ? "alert alert-success"
                                  : "alert alert-danger"
                              }
                              role="alert"
                            >
                              {this.state.message}
                            </div>
                          </div>
                          )}
                          <CheckButton
                            style={{ display: "none" }}
                            ref={c => {
                              this.checkBtn = c;
                            }}
                          />
                        </Form>

                      </div>
                      <div className ="jumbotron project-desc">
                        <h4 className="title_project">Mes documents :</h4> 
                        <ul class="nav nav-pills nav-justified">
                        {this.state.list_documents.filter(doc => doc.type === "my_documents").map(item => (
                            <li class="nav-item" key={item.id}>
                              <a href={URL_API+item.image} download target="_blank" rel="noopener noreferrer" className="doc-link">
                                <FileEarmarkTextFill color="black" size={96} /><br />{item.titre}</a>
                              <button onClick={() =>this.handleDelDoc(item.id)} class="btn btn-light buttaddoc" ><Trash color="red" size={16} /></button>
                            </li>
                          ))}
                          </ul>
                        <label>  
                          <div class="btn btn-light buttaddoc" ><PlusCircle /> Ajouter un document</div>
                          <div>
                            <input type="file" onChange={(e) => this.onFileChange(e,'my_documents')} hidden/>
                          </div>
                        </label>  
                          
                      </div>
                    </div>
                  )
                )}
                
                <div className ="jumbotron project-desc">
                  <h4 className="title_project">Mon Projet</h4>

                  <div className ="jumbotron project-all-doc">
                    <button onClick={this.showEco} class="btn buttshowdoc" >
                      <div className="row">
                        <div className="col project-title-left">Economie du Projet</div>
                        <div className="col-2">{this.state.list_documents.filter(doc => doc.type === "economy").length} Documents</div>
                      </div>
                    </button>
                      {this.state.show_economy && 
                      (<div>
                        <ul class="nav nav-pills nav-justified">
                        {this.state.list_documents.filter(doc => doc.type === "economy").map(item => (
                            <li class="nav-item" key={item.id}>
                              <a href={URL_API+item.image} download target="_blank" rel="noopener noreferrer" className="doc-link">
                                <FileEarmarkTextFill color="black" size={96} /><br />{item.titre}</a>
                              <button onClick={() =>this.handleDelDoc(item.id)} class="btn btn-light buttaddoc" ><Trash color="red" size={16} /></button>
                            </li>
                          ))}
                          </ul>
                        <label>  
                          <div class="btn buttaddoc" ><PlusCircle /> Ajouter un document</div>
                          <div>
                            <input type="file" onChange={(e) => this.onFileChange(e,'economy')} hidden/>
                          </div>
                        </label> 
                      </div>)
                      }
                  </div>

                  <div className ="jumbotron project-all-doc">
                    <button onClick={this.showEsq} class="btn buttshowdoc" >
                      <div className="row">
                        <div className="col project-title-left">Esquisse</div>
                        <div className="col-2">{this.state.list_documents.filter(doc => doc.type === "esquisse").length} Documents</div>
                      </div>
                    </button>
                      {this.state.show_esquisse && 
                      (<div>
                        <ul class="nav nav-pills nav-justified">
                        {this.state.list_documents.filter(doc => doc.type === "esquisse").map(item => (
                            <li class="nav-item" key={item.id}>
                              <a href={URL_API+item.image} download target="_blank" rel="noopener noreferrer" className="doc-link">
                                <FileEarmarkTextFill color="black" size={96} /><br />{item.titre}</a>
                              <button onClick={() =>this.handleDelDoc(item.id)} class="btn btn-light buttaddoc" ><Trash color="red" size={16} /></button>
                            </li>
                          ))}
                          </ul>
                        <label>  
                          <div class="btn buttaddoc" ><PlusCircle /> Ajouter un document</div>
                          <div>
                            <input type="file" onChange={(e) => this.onFileChange(e,'esquisse')} hidden/>
                          </div>
                        </label> 
                      </div>)
                      }
                  </div>

                  <div className ="jumbotron project-all-doc">
                    <button onClick={this.showBefPro} class="btn buttshowdoc" >
                      <div className="row">
                        <div className="col project-title-left">Avant Projet</div>
                        <div className="col-2">{this.state.list_documents.filter(doc => doc.type === "before_project").length} Documents</div>
                      </div>
                    </button>
                      {this.state.show_before_project && 
                      (<div>
                        <ul class="nav nav-pills nav-justified">
                        {this.state.list_documents.filter(doc => doc.type === "before_project").map(item => (
                            <li class="nav-item" key={item.id}>
                              <a href={URL_API+item.image} download target="_blank" rel="noopener noreferrer" className="doc-link">
                                <FileEarmarkTextFill color="black" size={96} /><br />{item.titre}</a>
                              <button onClick={() =>this.handleDelDoc(item.id)} class="btn btn-light buttaddoc" ><Trash color="red" size={16} /></button>
                            </li>
                          ))}
                          </ul>
                        <label>  
                          <div class="btn buttaddoc" ><PlusCircle /> Ajouter un document</div>
                          <div>
                            <input type="file" onChange={(e) => this.onFileChange(e,'before_project')} hidden/>
                          </div>
                        </label> 
                      </div>)
                      }
                  </div>

                  <div className ="jumbotron project-all-doc">
                    <button onClick={this.showPrepBuil} class="btn buttshowdoc" >
                      <div className="row">
                        <div className="col project-title-left">Préparation du chantier</div>
                        <div className="col-2">{this.state.list_documents.filter(doc => doc.type === "before_building").length} Documents</div>
                      </div>
                    </button>
                      {this.state.show_prep_building && 
                      (<div>
                        <ul class="nav nav-pills nav-justified">
                        {this.state.list_documents.filter(doc => doc.type === "before_building").map(item => (
                            <li class="nav-item" key={item.id}>
                              <a href={URL_API+item.image} download target="_blank" rel="noopener noreferrer" className="doc-link">
                                <FileEarmarkTextFill color="black" size={96} /><br />{item.titre}</a>
                              <button onClick={() =>this.handleDelDoc(item.id)} class="btn btn-light buttaddoc" ><Trash color="red" size={16} /></button>
                            </li>
                          ))}
                          </ul>
                        <label>  
                          <div class="btn buttaddoc" ><PlusCircle />Ajouter un document</div>
                          <div>
                            <input type="file" onChange={(e) => this.onFileChange(e,'before_building')} hidden/>
                          </div>
                        </label> 
                      </div>)
                      }
                  </div>

                </div> 
              </div>                
              ):(
                <div className="list-projects">
                  {this.state.list_projects.map(item => (
                  <li key={item.id}>
                    <div className ="jumbotron project-desc">
                      <div class="row align-items-center" >
                        <div class="col-4">
                          <img src="..." class="w-25" alt="IMAGE"></img>
                        </div>
                        <div class="col-8">
                          <h3>{item.ville}</h3>
                          {item.adresse}<br />
                          {item.besoin}<br />
                          <div class="col-2 ml-auto">
                          <button className="btn btn-outline-dark btn-block" value={item.id} onClick={this.onChangeIdProject}>Détails</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  ))}  
                </div>
              )}
            
          </div>
        </div>
      );
    }
  }